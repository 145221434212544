import '../css/app.css'
// import canvasConfetti from 'https://cdn.skypack.dev/canvas-confetti'
import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

document.addEventListener('click', (e) => {
	const target = e.target
	if (!target.classList.contains('js-smooth-scroll')) return
	e.preventDefault()
	const targetId = target.hash
	document.querySelector(targetId).scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	})
})

console.log('BOOM')
